import moment from 'moment';
import { Link } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getdateformat } from '../../utils/dateFormatterForTI/dateFormatter';
import { formatDate as formatDateKatex } from '../../utils/math';
import { fetchTeXCompiledCaption, modifyWebsiteName } from '../../utils/post/manageCaptions';
import { DateDifferenceContainer, handleDateDifferenceContainer } from "../../utils/profiles/dateDifference";
import { handleMarginsOfTimetablesParent, updateStylingOfUserCalendar } from '../../utils/profiles/handleCalendarStyling';
import { getActiveDatesOfTheMonth, getActiveDateBasedOnImportantEvent, determineDateToBeSelectForMonths } from '../../utils/timetableHelper/timetableHelper';
import VideoPlayer from "../VideoPlayer";
import { stringToDate, formatDate } from './Timetable';
import { saveTemporalInfo } from '../../Services/api';

export default function BookmarkTimetable({ isScreenVisible, subspaceNumber, calendar, timetableData, states, updateTimetableData, updateActiveDatesOfTimetable, getSavedTemporalInfo }) {
    const playerRefs = useRef({});
    const calendarRefs = useRef({});
    const [activeDate, setActiveDate] = useState();
    const [profileData, setProfileData] = useState();
    const [prevDate, setPrevDate] = useState('');
    const [nextDate, setNextDate] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [savePopupKey, setSavePopupKey] = useState("");
    const [isSaved, setIsSaved] = useState(false);
    const timeoutRefLable = useRef(null);
    const timeoutRefCalendar = useRef(null);
    const timeoutRef = useRef(null);
    const temporalInfoRefs = useRef({});
    const isCompressedCalender = timetableData.availableEventsOnDates?.length < 16;
    const userProfileType = localStorage.getItem('profileType');
    const [rerenderTrigger, setRerenderTrigger] = useState(0);
    let activeTimeoutId = null;

    useEffect(() => {
        setActiveDate(timetableData?.activeDate);
        setProfileData(timetableData?.profileData);
    }, [timetableData])

    useEffect(() => {
        if (activeDate) {
            updateCalendarStyling();
            fetchEventData(activeDate);
        }
    }, [activeDate])

    useEffect(() => {
        if (timetableData.activeDate) {
            getNextPrevDates(timetableData.activeDate);
        }
    }, [timetableData.activeDate])

    useEffect(() => {
        if (isScreenVisible) {
            let prevWidth = window.innerWidth;

            const handleResize = () => {
                const currentWidth = window.innerWidth;
                if (currentWidth !== prevWidth) {
                    updateMarginsOfTimetablesParent(timetableData.availableEventsOnDates);
                    prevWidth = currentWidth;
                }
            };

            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);

    useEffect(() => {
        return () => {
            if (activeTimeoutId) {
                clearTimeout(activeTimeoutId);
            }
        };
    }, []);

    useEffect(() => {
        updateMarginsOfTimetablesParent(timetableData.availableEventsOnDates);
    }, [profileData])

    const addTimeoutForDateChange = (callback, responseData) => {
        const now = new Date();
        const filteredData = responseData.filter(item => {
            const postedOnDate = item.postedOn ? new Date(item.postedOn * 1000) : null;
            const postedOn2Date = item.postedOn2 ? new Date(item.postedOn2 * 1000) : null;

            const daysDifferencePostedOn = postedOnDate
                ? Math.floor((now.getTime() - postedOnDate.getTime()) / (1000 * 3600 * 24))
                : Infinity;

            const daysDifferencePostedOn2 = postedOn2Date
                ? Math.floor((now.getTime() - postedOn2Date.getTime()) / (1000 * 3600 * 24))
                : Infinity;

            return daysDifferencePostedOn <= 7 || daysDifferencePostedOn2 <= 7;
        });

        if (filteredData.length === 0) return 0;

        const nextMidnight = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1,
            0, 0, 0, 0
        );

        const remainingTime = nextMidnight.getTime() - now.getTime();

        if (activeTimeoutId) {
            clearTimeout(activeTimeoutId);
        }

        activeTimeoutId = setTimeout(() => {
            try {
                callback(responseData);
            } catch (error) {
                console.error('Error updating temporal information:', error);
            }
        }, remainingTime);

        return remainingTime;
    };

    const updateTemporalInformation = (responseData) => {
        try {
            responseData.forEach((item) => {
                const ref = temporalInfoRefs.current[item.eventID];
                if (ref) {
                    const updatedContent = formatDateKatex(
                        getdateformat(item.postedOn, item.postedOn2, moment.tz.guess())
                    );

                    if (ref.innerHTML !== updatedContent) {
                        ref.innerHTML = updatedContent;
                    }
                }
            });

            if (responseData && responseData.length > 0) {
                addTimeoutForDateChange(updateTemporalInformation, responseData);
            }
        } catch (error) {
            console.error('Error in updating temporal information:', error);
        }
    };

    const updateCalendarData = (data) => {
        if (data) {
            updateTimetableData(data);
        }
    }

    const updateCalendarStyling = () => {
        const calendarRef = calendarRefs?.current;

        //initially update the calendar styling according to available temporal information
        if (calendarRef) {
            updateStylingOfUserCalendar(
                calendarRef,
                activeDate ? activeDate : null,
                {
                    valueCalendarOther: activeDate,
                    availableEventsOnDates: timetableData.availableEventsOnDates,
                    appendedTiles: timetableData.appendedTiles,
                    allEventsList: states.events,
                },
                updateCalendarData,
            );
        } else {
            setTimeout(() => {
                updateCalendarStyling();
            }, 1);
        }
    }

    const updateMarginsOfTimetablesParent = (availableEvents) => {
        const calendarRef = calendarRefs.current;

        if (calendarRef && availableEvents?.length) {
            handleMarginsOfTimetablesParent(calendarRef, availableEvents);
        }
    }

    const changeCurrentTimeOld = (seconds, playerId) => {
        const player = playerRefs.current[playerId];
        if (player) {
            const currentTime = player.getState().player.currentTime;
            player.seek(currentTime + seconds);
        }
    };

    const SaveTemporalInformation = async (post, popupKey) => {
        // Initially make the popup and key empty
        if (openPopup) {
            setOpenPopup(false);
            setSavePopupKey("");
            await new Promise((resolve) => setTimeout(resolve, 300));
        }

        const token = localStorage.getItem('token');
        const profileType = localStorage.getItem('profileType');

        if (!post?.eventID || profileType === 'producer') return;

        setSavePopupKey(popupKey);

        if (!token) {
            let savedPosts = JSON.parse(localStorage.getItem('savedTemporalInfo')) || [];
            const existingIndex = savedPosts.findIndex(savedPost => savedPost.eventID === post.eventID);

            if (existingIndex !== -1) {
                setIsSaved(false);
                savedPosts.splice(existingIndex, 1);
            }

            localStorage.setItem('savedTemporalInfo', JSON.stringify(savedPosts)); // Save new array to local storage
        } else {
            const result = await saveTemporalInfo(post?.eventID);
            console.log("save result", result);
            
            result.message === 'Event saved successfully' ? setIsSaved(true) : setIsSaved(false);
        }

        // Clear any existing timeout before setting a new one
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        setOpenPopup(true);
        timeoutRef.current = setTimeout(() => {
            setOpenPopup(false);
            setSavePopupKey("");
            timeoutRef.current = null;
            getSavedTemporalInfo({freshLoad: false});
            setRerenderTrigger(prev => prev + 1);
            // filterUnsavedDate(post);
        }, 3000);
    };

    const checkdateOther = (date) => {
        for (let index = 0; index < states.events.length; index++) {
            var dateads = stringToDate(states.events[index].eventOn, 'DD/MM/YYYY');
            var elx = new Date(dateads);
            var finaldate = moment(elx).format('MMYYYY');
            if (date == finaldate) {
                return states.events[index].eventOn;
            }
        }
    }

    const getNextPrevDates = (dates) => {
        const nextDate = timetableData?.nextDate ? moment(timetableData.nextDate).format("DD-MM-YYYY") : '';
        const prevDate = timetableData?.prevDate ? moment(timetableData.prevDate).format("DD-MM-YYYY") : '';
        setNextDate(nextDate);
        setPrevDate(prevDate);

        return { nextDate, prevDate };
    }

    const getEventsOfActiveDate = (date) => {
        const monthYear1 = moment(date).format('DD-MM-YYYY');
        let eventsOfThisDate = [];
        if (states.events && date) {
            states.events?.map((item) => {
                monthYear1 === item?.eventOn ? eventsOfThisDate.push(item) : eventsOfThisDate = eventsOfThisDate;
            })
        }
        return eventsOfThisDate;
    }

    const onCalendarOtherUser = (e) => {
        const isSameDate = e.getDate() === activeDate.getDate() &&
            e.getMonth() === activeDate.getMonth() &&
            e.getFullYear() === activeDate.getFullYear();

        if (isSameDate)
            return

        if (!timeoutRefCalendar.current) {
            timeoutRefCalendar.current = setTimeout(() => {
                const date = moment(e).format('DD-MM-YYYY');
                if (timetableData?.availableEventsOnDates && timetableData?.availableEventsOnDates?.find(x => x.eventOn === date)) {
                    updateTimetableData({
                        activeDate: e,
                        availableEventsOnActiveDate: getEventsOfActiveDate(e)
                    }); // update the new active date
                }
                timeoutRefCalendar.current = null;
            }, 50);
        }
    }

    const fetchEventData = async (date) => {        
        const token = localStorage.getItem('token');
        const formattedDate = moment(date).format('DD-MM-YYYY');
        const fetchedEvents = []; // Array to hold all events fetched for the date

        if (timetableData?.availableEventsOnActiveDate) {
            const fetchPromises = timetableData.availableEventsOnActiveDate.map(async (event) => {
                const userId = event?.eventBy || null;

                const url = process.env.REACT_APP_BASE_URL +
                    (token
                        ? `/api/event/profileEventDateWise.php?profileId=${userId}&date=${formattedDate}`
                        : `/api/event/profileEventDateWiseGuest.php?profileId=${userId}&date=${formattedDate}`
                    );

                try {
                    const response = await fetch(url, {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "TimeZone": moment.tz.guess(),
                            "Authorization": token ? `Bearer ${token}` : undefined,
                        },
                    });
                    const responseJson = await response.json();

                    if (responseJson?.response?.length) {
                        addTimeoutForDateChange(updateTemporalInformation, responseJson.response);
                    }

                    fetchedEvents.push({
                        userId: userId,
                        user: responseJson.response?.length ? responseJson.response[0]?.screenName : "",
                        pathname: responseJson.response?.length ? responseJson.response[0]?.username : "",
                        eventDetail: responseJson.response,
                    });
                } catch (error) {
                    console.log('Error fetching data:', error);
                }
            });

            // Wait for all fetch requests to complete
            await Promise.all(fetchPromises);

            // Update the timetable data after all events have been fetched
            updateTimetableData({ profileData: fetchedEvents });
        }
    };

    const handleLabelChange = (action, value) => {
        if (!timeoutRefLable.current) {
            timeoutRefLable.current = setTimeout(() => {
                const currentMonth = formatDate(activeDate, "MMYYYY")
                const newMonth = formatDate(value, "MMYYYY")

                let updateAction = action
                let updateDate

                if (action === 'next') {
                    updateDate = timetableData?.nextDate;
                } else if (action === 'prev') {
                    updateDate = timetableData?.prevDate;
                }
                else if (action == 'onChange' && newMonth != currentMonth) {
                    const change = value.getTime() - activeDate.getTime();

                    if (!change)
                        return

                    updateAction = 'prev';
                    if (change > 0)
                        updateAction = 'next';

                    updateDate = value
                }
                if (updateDate) {
                    updateActiveDatesOfTimetable(updateDate, updateAction);
                    onCalendarOtherUser(updateDate);
                    getNextPrevDates(updateDate);
                }
                timeoutRefLable.current = null;
            }, 50); // timeout to remove the glitch between tiles updation
        }
    }

    const tileClassName = ({ activeStartDate, date }) => {
        if (states.events.length > 0) {
            const formattedDate = formatDate(date, "DD-MM-YYYY");
            const event = timetableData?.availableEventsOnDates && timetableData?.availableEventsOnDates.find(x => x.eventOn === formattedDate);

            if (formattedDate === formatDate(activeDate, "DD-MM-YYYY")) {
                return 'orange';
            }

            if (event?.tile_type) {
                return event.tile_type
            }

            const eventsOfThisDate = getEventsOfActiveDate(date);

            if (event) {
                if (eventsOfThisDate.find(x => x.importantEvents === 1)) {
                    return 'darkblue';
                }

                if (eventsOfThisDate?.length === 1) {
                    return 'highlight';
                }

                if (eventsOfThisDate?.length > 1) {
                    return 'darkblue';
                }

                return 'yellow';
            } else {
                return 'blocklight' + (isCompressedCalender ? " dateOff" : '');
            }
        }
        return '';
    };

    return (
        <>
            {activeDate && <div className={`sectionone sectionone_div ${calendar}`}>
                <div className="inkap emptybio prin">
                    <div className="organizationTitle">
                        {profileData.length > 0 ? (
                            profileData.map((dataItem, index) => (
                                <React.Fragment key={index}>
                                    <Link to={`/${dataItem?.pathname}`}>{dataItem?.user}</Link>
                                    {index < profileData.length - 1 && <span className="comma">,</span>}
                                </React.Fragment>
                            ))
                        ) : (
                            "" // Renders blank initially when data is in progress
                        )}
                    </div>
                    <div className="inkapil">
                        <div>
                            <div class="table" ref={calendarRefs}>
                                <Calendar
                                    key={`calendar-${rerenderTrigger}`}
                                    onChange={(e) => onCalendarOtherUser(e)}
                                    activeStartDate={activeDate}
                                    locale="en-US" // Set locale to US English
                                    firstDayOfWeek={0}
                                    minDate={timetableData.minDate}
                                    maxDate={timetableData.maxDate}
                                    onActiveStartDateChange={({ action, value }) => handleLabelChange(action, value)}
                                    tileClassName={tileClassName}
                                />
                            </div></div>
                    </div>
                </div>
                {<div className="mb-2">
                    {profileData && profileData.length > 0 ? profileData.map((dataItem, index) => {
                        const eventData = dataItem?.eventDetail;

                        return eventData && eventData.map((event, eventIndex) => {
                            return (
                                <div className='temporal-information-block' key={`${index}-${eventIndex}`}>
                                    {/* Buttons */}
                                    <div className="likeaddto" id={"promo" + event.eventID}>
                                        <img className="w-100" src="../../images/More.png" alt="More" />
                                    </div>
                                    <div className="likeaddto" id={"promi" + event.eventID}>
                                        <img className="w-100" src="../../images/Erase.png" alt="Erase" />
                                    </div>

                                    {/* Main Event Container */}
                                    <div id={"crox" + event.eventID} className="postlike">
                                        <div className="userin rmrf">
                                            <h1 className={`new-heading-tex-789 ${userProfileType === 'producer' && 'disableSaveInfo'}`} onMouseOver={(e) => handleDateDifferenceContainer(e, subspaceNumber)}>
                                                <div
                                                    style={{ width: 'fit-content' }}
                                                    ref={(el) => (temporalInfoRefs.current[event.eventID] = el)}
                                                    onClick={() => SaveTemporalInformation(event, `key-${index}-${eventIndex}`)}
                                                    dangerouslySetInnerHTML={{ __html: formatDateKatex(getdateformat(event.postedOn, event.postedOn2, event.timezone)) }}
                                                ></div>
                                                {(openPopup && savePopupKey === `key-${index}-${eventIndex}`) && <div className='TIActionStatus'>
                                                    <p>{isSaved ? "Saved" : "Unsaved"}</p>
                                                </div>}
                                            </h1>
                                            <DateDifferenceContainer />
                                            <h5>
                                                <div className='katexParentBlock'>
                                                    {event?.link ?
                                                        <a href={event.link} target='_blank' rel="noopener noreferrer">
                                                            <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(event.status) }} />
                                                            <p className='hoveredLink'>
                                                                {modifyWebsiteName(event.link)}{modifyWebsiteName(event.link)?.endsWith("/") && <label className="hdots">...</label>}
                                                            </p>
                                                        </a>
                                                        : <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(event.status) }} />}
                                                </div>
                                            </h5>
                                        </div>

                                        {/* Media Content */}
                                        <div className="post">
                                            {(() => {
                                                if (event.mediaType === "video") {
                                                    return (
                                                        <div id={'Video' + event.eventID} className="video-div">
                                                            <VideoPlayer
                                                                updateMarginsOfTimetablesParent={updateMarginsOfTimetablesParent}
                                                                timetableData={timetableData}
                                                                id={'Video' + event.eventID}
                                                                url={event.eventImage}
                                                            />
                                                        </div>
                                                    );
                                                } else if (event.mediaType === "image") {
                                                    return (
                                                        <img className="w-100 mt-1" src={`${process.env.REACT_APP_BASE_URL}${event.eventImage}`} alt="Event" />
                                                    );
                                                }
                                                return null;
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            );
                        });
                    }) : ""}

                </div>}
            </div>
            }
        </>
    )
}