import React from 'react';

// InvalidUsernameError Component
export const InvalidUsernameError = () => {
    const popupStyle = { fontFamily: 'Times', fontSize: '20px' };
    const titleStyle = { marginBottom: '8px' };
    const listStyle = { listStyle: 'none', padding: 0 };

    return (
        <div style={popupStyle}>
            <div style={titleStyle}>An identifier must...</div>
            <ul style={listStyle} className="custom-list">
                <li className="custom-list-item">consist only of lower-case English letters and hyphens,</li>
                <li className="custom-list-item">not have hyphens in immediate succession,</li>
                <li className="custom-list-item">not begin nor end with a hyphen.</li>
            </ul>
        </div>
    );
};

const CustomPopUp = ({ isOpen, onClose, popup, multipleButton }) => {
    if (!isOpen) return null;

    const handleButtonClick = () => {
        popup.onClick();
        onClose();
    }

    return (
        <div className={`popup ${isOpen ? 'open' : ''}`}>
            <div className="cover-block" onClick={onClose}></div>
            <div className="content">
                <div className='heading'>
                    {popup && (
                        <h4 className="text-center width-100">{popup.heading}</h4>
                    )}
                </div>
                <div className='message'>
                    {popup && popup.message && (
                        typeof popup.message === 'string'
                            ? <p className="text-left width-100">{popup.message}</p>
                            : popup.message
                    )}
                </div>
                {!multipleButton ? (<div className='operators'>
                    <button className="operators-button" onClick={onClose}>OKAY</button>
                </div>)
                    : (<div className='operators'>
                        <button className="operators-button" onClick={onClose}>CANCEL</button>
                        <button className="operators-button" onClick={handleButtonClick}>YES</button>
                    </div>
                    )}
            </div>
        </div>
    );
};

export default CustomPopUp;