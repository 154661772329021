import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Player, ControlBar } from 'video-react';
import $ from 'jquery';
import { Button } from 'reactstrap';
import moment from 'moment';
import { Calendar } from 'react-calendar';
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Footer from "../Footer";
import { ReactComponent as RevisitSvg } from '../../utils/svg/Revisit.svg';
import { userListForLogin } from '../../Services/api';
import { checkboxChecked } from '../../utils/math';
import CustomPopUp from '../../CustomPopup';
export const DATE_TIME_LATEST_POST = "YYYY-MM-DDTHH:mm";
export const DATE_LATEST_POST = "YYYY-MM-DD";
class EditPost extends React.Component {
   constructor(props) {

      super(props);
      let edit_id = null;
      const path = this.props.location.pathname;
      const params = this.props.location.pathname.split('/');
      if (params[params.length - 1] != 'EditPost') {
         edit_id = params[params.length - 1];
      }
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL, editwithwho: [], hourObj: {}, selectedDate: "", mutuality: "", selectedHour: "", alphabetically: "", mode: "", croseIcone: "", firstDate: "", selectedMode: "", selectedMinutes: "", selectedMinutes2: "",
         editwhocansee: "", selectedtypefinal: 'everyone', filewithpath: "", postType2: "", imageVideoUrl2: "",
         activecolor: 'page', perpage: 1, searchPerameter: "", followers: [], items: Array.from({ length: 40 }), hasMore: true, followerSearch: [], itemsearchfo: "", checkedItems: new Map(),
         checkedexcept: new Map(),
         checkedunexcept: new Map(), editevent: edit_id, editstatus: "", editeventImage: "", editmediaType: "", editwhocansee: "", isVideoUploading: false,
         editwhocanseemeta: [], editheight: "", editwidth: "", editinclude_in_feed: "", editIsImportant: "", backid: "#one_16", exp: false, changewithwho: false,
         secondDate: '', secondHourObj: {}, secondSelectedMinutes: '', secondSelectedHour: '', secondSelectedMode: '', secondMode: '',
      }
      this.getTimeEdit = this.getTimeEdit.bind(this);
      this.getminEdit = this.getminEdit.bind(this);
   }
   componentDidMount() {
      localStorage.setItem("footer", "first");

      this.setState({
         editstatus: "", editeventImage: "", editmediaType: "", editwhocansee: "",
         editwhocanseemeta: [], editwithwho: [], editheight: "", editwidth: "", backid: "#one_16", exp: false, changewithwho: false
      });
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/event/getEventById.php?eventID=' + this.state.editevent, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (responseJson.success == true) {
               this.setState({
                  editlike: responseJson.response[0].likedByUsers, editcount: responseJson.response[0].commentCounts,
                  editcomment: responseJson.response[0].commentByUsers, editpostOn: responseJson.response[0].postedOn, editevent: this.state.editevent,
                  editstatus: responseJson.response[0].status, editeventImage: responseJson.response[0].eventImage, editmediaType: responseJson.response[0].mediaType,
                  editwhocansee: responseJson.response[0].whocansee, editwhocanseemeta: responseJson.response[0].whocanseemeta, editwithwho: responseJson.response[0].withwho,
                  editheight: responseJson.response[0].height, editwidth: responseJson.response[0].width, editinclude_in_feed: responseJson.response[0].include_in_feed, editIsImportant: responseJson.response[0].isImportant
               }, () => { this.updateCustomCheckbox() });
            }
            var timestamp = responseJson.response[0].postedOn;
            let { hourObj, date: e, dateStr: date, hours, minutes, mode, combine } = this.getPostOnDetail(timestamp)

            let timestamp2 = responseJson.response[0].postedOn2

            this.setState({
               hourObj: hourObj,
               selectedDate: e,
               selectedHour: hours,
               selectedMinutes: minutes,
               selectedMode: combine,
               firstDate: date,
               mode: mode,
            });
            const timeNotDefined = combine == '12am' && minutes == '0';
            if (!timeNotDefined) {
               $('#' + combine).addClass('timecolor');
               $('#' + minutes).addClass('timecolor');
            }

            if (timestamp2) {
               let secondDateInfo = this.getPostOnDetail(timestamp2)
               this.setState({
                  secondDate: secondDateInfo.dateStr,
                  secondHourObj: secondDateInfo.hourObj,
                  secondSelectedMinutes: secondDateInfo.minutes,
                  secondSelectedHour: secondDateInfo,
                  secondMode: secondDateInfo.mode,
                  secondSelectedMode: secondDateInfo.combine
               });
               if (secondDateInfo.combine != combine)
                  $('#' + this.state.secondSelectedMode).addClass('timecolorsecond');

               if (secondDateInfo.minutes != minutes)
                  $('#' + this.state.secondSelectedMinutes).addClass('timecolorsecond');
            }
         })
         .catch((error) => {
         })
      $(".newscreen01 #one_16 .more3").click(function () {
         $(".newscreen01 #one_16 .pupup6").toggle();
         $(".newscreen01 #one_16 .pupup6").addClass("zIndex");
      });
      $(".newscreen01 #one_16 .errorbtn").click(function () {
         $(".newscreen01 #one_16 .errorpop").toggle();
      });
   }

   getPostOnDetail(timestamp) {
      var hours = moment.unix(timestamp).format("H");
      var mode = moment.unix(timestamp).format("a");
      var combine = hours + mode;
      var minutes = moment.unix(timestamp).format("mm");
      var date = moment.unix(timestamp).format("YYYY-MM-DD");
      var hourObj;
      if (mode == "pm" && hours == 12) { // 12 PM issue
         hourObj = { time: `${hours}${mode}`, value: parseInt(hours) };
      } else {
         hourObj = { time: `${hours}${mode}`, value: mode == "pm" ? parseInt(hours) + 12 : parseInt(hours) };
      }
      var minObj = { minutes: minutes, minValue: parseInt(minutes) };
      var ds = moment(date);
      var e = new Date(ds);
      return { hourObj, date: e, dateStr: date, hours, minutes, mode, combine }
      // this.setState({hourObj:hourObj,selectedDate: e,selectedHour: hours,selectedMinutes: minutes,selectedMode: combine,firstDate:date,mode:mode});
      // $('#'+combine).addClass('timecolor');
      // $('#'+minutes+"edit").addClass('timecolor');
   }
   handleChangewithwho(event) {
      if (this.state.editwithwho && this.state.editwithwho.length > 0) {
         var array = this.state.editwithwho;
         var index = array.findIndex(x => x.user_id === event.target.value);
         if (index !== -1) {
            array.splice(index, 1);
         }
         this.setState({ editwithwho: array });
      }
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#slug' + item).addClass('active');
      }
      else {
         $('#slug' + item).removeClass('active');
      }
      this.setState({ changewithwho: true });
      this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
   }

   handleChangeexcept(event) {
      if (this.state.editwhocanseemeta && this.state.editwhocanseemeta.length > 0 && this.state.editwhocansee == "allfollowersexcept") {
         var array = this.state.editwhocanseemeta;
         var index = array.findIndex(x => x.user_id === event.target.value);
         if (index !== -1) {
            array.splice(index, 1);
         }
         this.setState({ editwhocanseemeta: array });
      }
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#elug' + item).addClass('active');
      }
      else {
         $('#elug' + item).removeClass('active');
      }
      this.setState({ exp: true });
      this.setState(prevState => ({ checkedexcept: prevState.checkedexcept.set(item, isChecked) }));
   }
   handleChangeexceptsearch(event) {
      if (this.state.editwhocanseemeta && this.state.editwhocanseemeta.length > 0 && this.state.editwhocansee == "allfollowersexcept") {
         var array = this.state.editwhocanseemeta;
         var index = array.findIndex(x => x.user_id === event.target.value);
         if (index !== -1) {
            array.splice(index, 1);
         }
         this.setState({ editwhocanseemeta: array });
      }
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#elugsearch' + item).addClass('act');
      }
      else {
         $('#elugsearch' + item).removeClass('act');
      }
      this.setState({ exp: true });
      this.setState(prevState => ({ checkedexcept: prevState.checkedexcept.set(item, isChecked) }));
   }
   handleChangeunexcept(event) {
      if (this.state.editwhocanseemeta && this.state.editwhocanseemeta.length > 0 && this.state.editwhocansee == "particularfollowers") {
         var array = this.state.editwhocanseemeta;
         var index = array.findIndex(x => x.user_id === event.target.value);
         if (index !== -1) {
            array.splice(index, 1);
         }
         this.setState({ editwhocanseemeta: array });
      }
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#these' + item).addClass('active');
      }
      else {
         $('#these' + item).removeClass('active');
      }
      this.setState({ exp: true });
      this.setState(prevState => ({ checkedunexcept: prevState.checkedunexcept.set(item, isChecked) }));
   }
   handleChangeunexceptsearch(event) {

      if (this.state.editwhocanseemeta && this.state.editwhocanseemeta.length > 0 && this.state.editwhocansee == "particularfollowers") {
         var array = this.state.editwhocanseemeta;
         var index = array.findIndex(x => x.user_id === event.target.value);
         if (index !== -1) {
            array.splice(index, 1);
         }
         this.setState({ editwhocanseemeta: array });
      }
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#thesesearch' + item).addClass('act');
      }
      else {
         $('#thesesearch' + item).removeClass('act');
      }
      this.setState({ exp: true });
      this.setState(prevState => ({ checkedunexcept: prevState.checkedunexcept.set(item, isChecked) }));
   }

   async fetchFilterUser() {
      this.pickColor();
      // if(JSON.parse(localStorage.getItem("entry")) <= 0 | JSON.parse(localStorage.getItem("entry")) == null)
      // {
      // const token = localStorage.getItem('token');
      // fetch(this.state.baseUrl+'/api/group/user.php',{
      // method: "GET",
      // headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      // "Authorization": "Bearer " + token,
      // },     
      // })
      // .then((response) => response.json())
      // .then((responseJson) =>{
      // this.setState({recomended:responseJson.response});
      // localStorage.setItem("entry", JSON.stringify(this.state.recomended));
      // }).catch((error)=>{
      // console.log(error);
      // })
      // }
      // else
      // {
      // this.setState({recomended:[],recomended:JSON.parse(localStorage.getItem("entry"))});
      // }
      let data = await userListForLogin(1);
      this.setState({ recomended: data });
   }

   setStorage() {
      localStorage.setItem("backid", "front");
   }
   closeImage() {
      this.setState({ postType2: "", imageVideoUrl2: "", editeventImage: "", editmediaType: "" });
   }
   removefollowertext() {
      document.getElementById("lemon").value = "";
      this.setState({ followerSearch: [], searchPerameter: "", croseIcone: "" });
      this.gofollower();
   }
   gotouser() {
      localStorage.setItem("backid", "back");
      this.props.history.goBack();
   }
   gobackone() {
      $("#one_11").removeClass('active fades');
      $("#one_16").addClass('active fadesback');
   }
   gobacktwo() {
      $("#one_19").removeClass('active fades');
      $("#one_16").addClass('active fadesback');
   }
   gobackthree() {
      $("#one_20").removeClass('active fades');
      $("#one_16").addClass('active fadesback');
   }
   changeCurrentTime(seconds) {
      return () => {
         const { player } = this.player.getState();
         this.player.seek(player.currentTime + seconds);
      };
   }
   onCalendarPostEdit = (e) => {
      var dateStr = moment(e).format('YYYY-MM-DD');
      if (this.state.firstDate === dateStr) {
         this.setState({ selectedDate: '' });
         this.setState({ firstDate: '' });
         return
      }
      if (this.state.secondDate === dateStr) {
         this.setState({ selectedDate: '', secondDate: '' });
         return
      }
      let selectedDate = new Date(dateStr)
      if (!this.state.firstDate) {
         if (this.state.secondDate && new Date(this.state.secondDate) < selectedDate)
            this.setState({ selectedDate: e, firstDate: this.state.secondDate, secondDate: dateStr });
         else
            this.setState({ selectedDate: e, firstDate: dateStr });
      }
      else if (this.state.firstDate) {
         let firstDateDate = new Date(this.state.firstDate)
         if (selectedDate > firstDateDate)
            this.setState({ secondDate: dateStr });
         else
            this.setState({ selectedDate: "", secondDate: this.state.firstDate, firstDate: dateStr });
      }
   }
   getTimeEdit(event) {

      var item = event.target.id;
      var items = event.target.className;
      if (this.state.selectedMode === item) {
         this.setState({
            selectedHour: "",
            selectedMode: "",
            mode: "",
            hourObj: {},
         });
         $('.newscreen01 #' + item).removeClass('timecolor');
         return
      }
      if (this.state.selectedMode && this.state.secondSelectedMode)
         $('.newscreen01 #' + this.state.secondSelectedMode).removeClass('timecolorsecond');

      if (this.state.secondSelectedMode === item) {
         this.setState({
            secondSelectedHour: '',
            secondSelectedMode: '',
            secondMode: '',
            secondHourObj: {},
         });
         $('.newscreen01 #' + item).removeClass('timecolorsecond');
         return
      }

      if (item.slice(-2) == "pm" && items == 12) { // 12 PM issue
         var hourObj = { time: `${items}${item.slice(-2)}`, value: parseInt(items) };
      } else {
         var hourObj = { time: `${items}${item.slice(-2)}`, value: item.slice(-2) == "pm" ? parseInt(items) + 12 : parseInt(items) };
      }

      if (!this.state.selectedHour) {
         // this.setState({selectedHour:items,timedata2:item,hourObj:hourObj});
         this.setState({ selectedHour: items, selectedMode: item, mode: item.slice(-2), hourObj: hourObj });
         $('.newscreen01 #' + item).addClass('timecolor');
      }
      else {
         this.setState({ secondSelectedHour: items, secondSelectedMode: item, secondMode: item.slice(-2), secondHourObj: hourObj });
         $('.newscreen01 #' + item).addClass('timecolorsecond');
      }
   }
   getminEdit(event) {
      var item = event.target.id;
      var items = event.target.className;
      if (this.state.selectedMinutes === item) {
         $('.newscreen01 #' + item).removeClass("timecolor");
         this.setState({ selectedMinutes: "" });
         return;
      }
      if (this.state.secondSelectedMinutes === item) {
         $('.newscreen01 #' + item).removeClass("timecolorsecond");
         this.setState({ secondSelectedMinutes: "" });
         return;
      }
      if (this.state.selectedMinutes && this.state.secondSelectedMinutes)
         $('.newscreen01 #' + this.state.secondSelectedMinutes).removeClass("timecolorsecond");

      if (!this.state.selectedMinutes) {
         $('.newscreen01 #' + item).addClass("timecolor");
         this.setState({ selectedMinutes: items });
      } else {
         $('.newscreen01 #' + item).addClass("timecolorsecond");
         this.setState({ secondSelectedMinutes: items });
      }
   }
   withwho() {
      $("#one_11").removeClass("active fades");
      $("#one_16").addClass("active fadesback");
      if (this.state.checkedItems) {
         var newarray = [];
         this.state.checkedItems.forEach(function (value, key) {
            if (value == true && key != 0) {
               newarray.push(key);
            }
         });
         if (this.state.editwithwho) {
            this.state.editwithwho.forEach(function (value, key) {
               if (key != 0) {
                  newarray.push(key);
               }
            });
         }
         this.setState({ editwithwho: [], editwithwho: newarray });
      }
   }
   unexcept() {
      this.setState({ editwhocansee: 'particularfollowers', selectedtypefinal: 'only these followers...' }, () => {
         $("#one_20").removeClass("active fades");
         $("#one_16").addClass("active fadesback");
      });
      $(".more3").addClass("protect");
      if (this.state.checkedunexcept) {
         var newarray = [];
         this.state.checkedunexcept.forEach(function (value, key) {
            if (value == true && key != 0) {
               newarray.push(key);
            }
         });
         this.setState({ editwhocanseemeta: newarray });
      }
   }
   except() {
      this.setState({ editwhocansee: 'allfollowersexcept', selectedtypefinal: 'all followers except...' }, () => {
         $("#one_19").removeClass("active fades");
         $("#one_16").addClass("active fadesback");
      });
      $(".more3").addClass("protect");
      if (this.state.checkedexcept) {
         var newarray = [];
         this.state.checkedexcept.forEach(function (value, key) {
            if (value == true && key != 0) {
               newarray.push(key);
            }
         });
         this.setState({ editwhocanseemeta: newarray });
      }
   }
   onKeyUpValueFollower(event) {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/follow/followerSearch.php?user_id=' + variable + '&page=1&search=' + event.target.value + '&limit=40', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            $('.userin').removeClass('active');
            this.setState({ followerSearch: responseJson.response });
         });
      this.setState({ searchPerameter: "", searchPerameter: event.target.value, statepage: 0, searchPage: 1 });
      this.setState({
         itemsearchfo: Array.from({ length: 40 })
      });
      if (event.target.value.length > 0) {
         this.setState({ croseIcone: event.target.value });
      }
      else {
         this.setState({ croseIcone: "" });
      }
   }
   fetchMoreDatafollowerSearch() {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      const totaluser = localStorage.getItem('followers');
      const pageno = 40;
      this.state.statepage = +40;
      this.setState({ statepage: this.state.statepage });
      if (this.state.followerSearch.length >= this.state.statepage) {
         this.state.searchPage = +1;
         this.setState({ searchPage: this.state.searchPage });
      }
      else {
         this.setState({ hasMore: false });
         return;
      }
      setTimeout(() => {
         fetch(this.state.baseUrl + '/api/follow/followerSearch.php?user_id=' + variable + '&page=' + this.state.searchPage + '&search=' + this.state.searchPerameter + '&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.followerSearch.push(responseJson.response[i]);
               }
               this.setState({
                  followerSearch: this.state.followerSearch
               });
            }, 3000);
      });

      this.setState({
         itemsearchfo: this.state.itemsearch.concat(Array.from({ length: 40 }))
      });
   }
   checkcondition(data) {
      if (data == "everyone") {
         $('.more3').removeClass('protect');
         return "everyone";
      }
      else if (data == "followers") {
         $('.more3').removeClass('protect');
         return "followers";
      }
      else if (data == "following") {
         $('.more3').addClass('protect');
         return "users I'm following";
      }
      else if (data == "onlyme") {
         $('.more3').removeClass('protect');
         return "only me";
      }
      else if (data == "particularfollowers") {
         $('.more3').addClass('protect');
         return 'only these followers...';
      }
      else if (data == "allfollowersexcept") {
         $('.more3').addClass('protect');
         return 'all followers except...';
      }
   }
   onEveryoneEdit() {
      this.setState({ editwhocansee: 'everyone' });
      $("more3").removeClass("protect");
      $("#one_16 .pupup6").removeClass("zIndex");
   }
   onFollowersEdit() {
      this.setState({ editwhocansee: 'followers', selectedtypefinal: 'followers' });
      $(".more3").removeClass("protect");
   }
   onFollowingEdit() {
      this.setState({ editwhocansee: 'following', selectedtypefinal: "users I'm following" });
      $(".more3").addClass("protect");
   }
   onOnlymeEdit() {
      this.setState({ editwhocansee: 'onlyme', selectedtypefinal: 'only me' });
      $(".more3").removeClass("protect");
      $("#one_16 .pupup6").removeClass("zIndex");
   }
   onCancelEdit() {
      $("#one_16 .pupup6").removeClass("zIndex");
   }
   checkedit() {
      if (this.state.editinclude_in_feed == 0) {
         this.setState({ editinclude_in_feed: 1 });
      }
      else {
         this.setState({ editinclude_in_feed: 0 });
      }
   }

   setEditIsImportant() {
      this.setState({
         editIsImportant: $(".newscreen01 #editImportant").is(":checked"),
      });
      this.updateCustomCheckbox()
   }
   updateCustomCheckbox() {
      if ($(".newscreen01 #editImportant").is(":checked"))
         $(".newscreen01 #editImportant ~ .radiobtn").html(`<img src='/images/Checkmark.png' alt='active-img' />`);
      else
         $(".newscreen01 #editImportant ~ .radiobtn").html(``);
   }

   editPostImage = e => {
      let file = e.target.files[0];
      this.setState({ filewithpath: file });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
         var streetaddress = reader.result.substr(0, reader.result.indexOf('/'));
         if (streetaddress == "data:image") {
            this.setState({ postType2: "image" });
         }
         if (streetaddress == "data:video") {
            this.setState({ postType2: "video" });
         }
         if (this.state.postTypt2 != "") {
            this.setState({
               imageVideoUrl2: reader.result, editeventImage: reader.result
            });
         }
      };
   }
   handleChangewithwhoplug(event) {

      if (this.state.editwithwho && this.state.editwithwho.length > 0) {
         var array = this.state.editwithwho;
         var index = array.findIndex(x => x.user_id === event.target.value);
         if (index !== -1) {
            array.splice(index, 1);
         }
         this.setState({ editwithwho: array });
      }
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#plug' + item).addClass('act');
      }
      else {
         $('#plug' + item).removeClass('act');
      }
      this.setState({ changewithwho: true });
      this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
   }
   eleven() {
      $('#one_16').removeClass('active fadesback');
      $('#one_16').removeClass('active fades');
      $('#one_11').addClass('active fades');
      this.gofollower();
   }
   nineghty() {
      $('#one_16').removeClass('active fadesback');
      $('#one_16').removeClass('active fades');
      $('#one_19').addClass('active fades');
      this.gofollower();
   }
   twenty() {
      $('#one_16').removeClass('active fadesback');
      $('#one_16').removeClass('active fades');
      $('#one_20').addClass('active fades');
      this.gofollower();
   }
   gofollower() {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      this.setState({ activecolor: 'page', perpage: 1, searchPerameter: "", followers: [], items: 0, items: Array.from({ length: 40 }), hasMore: true });
      var pagepro = 1;
      fetch(this.state.baseUrl + '/api/follow/getFollowers.php?user_id=' + variable + '&page=' + pagepro + '&limit=40', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (this.state.mutuality != "") {
               this.setState({ followers: responseJson.response.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() });
            }
            if (this.state.alphabetically != "") {
               this.setState({
                  followers: responseJson.response.sort((a, b) =>
                     a.screenName.localeCompare(b.screenName))
               });
            }
            else {
               this.setState({ followers: responseJson.response, loading: "false" });
            }

         });
   }
   weekforNext() {
      if (this.state.followers.length > 0 | this.state.followerSearch.length > 0) {
         return "loaderclass load";
      }
      else {
         return "loaderclass";
      }
   }

   getPostedOnDates() {
      let {
         firstDate,
         hourObj,
         selectedMinutes,
         selectedHour,
         secondDate,
         secondHourObj,
         secondSelectedMinutes,
         secondSelectedHour,
      } = this.state;

      let fromDateObj = {
         dateStr: "",
         selectedHour: "",
         hourObj: {},
         selectedMinutes: "",
      };
      let toDateObj = { ...fromDateObj };

      fromDateObj.dateStr = firstDate || secondDate;
      fromDateObj.hourObj = hourObj;
      fromDateObj.selectedMinutes = selectedMinutes;
      fromDateObj.selectedHour = selectedHour;

      if (secondDate || secondHourObj.value || secondSelectedMinutes) {
         toDateObj.dateStr = secondDate || firstDate;
         toDateObj.selectedHour = secondSelectedHour;

         if (secondHourObj.value) {
            toDateObj.hourObj = secondHourObj;
         } else if (fromDateObj.dateStr == toDateObj.dateStr && secondSelectedMinutes) {
            toDateObj.hourObj = hourObj;
            toDateObj.selectedHour = selectedHour;
         }

         toDateObj.selectedMinutes = secondSelectedMinutes;
      }

      console.log({ fromDateObj, toDateObj });

      var postedOn = 0,
         postedOn2 = 0,
         newDate = "";

      if (fromDateObj.dateStr)
         var { momentString: postedOn, newDate: newDate } =
            this.getMomentAndDate(fromDateObj);

      if (toDateObj.dateStr)
         var { momentString: postedOn2 } = this.getMomentAndDate(toDateObj);

      return { postedOn, postedOn2, newDate };
   }

   getMomentAndDate({ dateStr, selectedHour, hourObj, selectedMinutes }) {

      const hour = Number(hourObj.value) < 10 ? `0${Number(hourObj.value)}` : Number(hourObj.value);
      var min = "00";
      if (selectedMinutes) {
         min = Number(selectedMinutes) < 10 ? `0${Number(selectedMinutes)}` : Number(selectedMinutes);
      }
      let postedOnString;
      if (selectedHour === "" && min === "00") {
         postedOnString = `${dateStr}`;
      } else {
         postedOnString = `${dateStr} ${hour || "00"}:${min}:00`;
      }
      let newDate;
      if (hour === "00" && min === "00") {
         newDate = moment(postedOnString).format(DATE_LATEST_POST);
      } else {
         newDate = moment(postedOnString).format(DATE_TIME_LATEST_POST);
      }
      var momentString = moment(newDate).unix();
      return { newDate, momentString };
   }


   editPost() {
      //  var hour;
      //  if (this.state.hourObj.value) {
      //    hour = Number(this.state.hourObj.value) < 10 ? `0${Number(this.state.hourObj.value)}` : Number(this.state.hourObj.value);
      //  } else {
      //    hour = '00';
      //  }
      //  var min = "00";
      //  if (this.state.selectedMinutes) {
      //  min = Number(this.state.selectedMinutes) < 10 ? `0${Number(this.state.selectedMinutes)}` : Number(this.state.selectedMinutes);
      //  }
      //  var postedOnString;
      //  if (this.state.firstDate) {
      //    postedOnString = `${this.state.firstDate} ${hour}:${min}:00`;
      //  } else {
      //    console.log('select the date');
      //    return;
      //  }
      // //  console.log("postedOnString:",postedOnString);
      //  var newDate = moment(postedOnString).format(DATE_TIME_LATEST_POST);
      // //  console.log("newDate:",newDate);
      //  var momentString = moment(newDate).unix();
      // //  console.log("momentString:",momentString);

      if (this.state.firstDate == "" && this.state.secondDate == "") {
         this.setState({ editerror2: 'Select the date,' + " " + localStorage.getItem('screenname').split(" ")[0] });
         $(".errorpop").toggle();
         return;
      }

      let postedOnDates = this.getPostedOnDates()
      console.log(postedOnDates);
      let { postedOn, postedOn2, newDate } = postedOnDates

      if (postedOn2 && postedOn > postedOn2)
         [postedOn, postedOn2] = [postedOn2, postedOn]

      var formData = new FormData();
      formData.append("eventID", this.state.editevent);
      formData.append("include_in_feed", this.state.editinclude_in_feed);
      formData.append("isImportant", this.state.editIsImportant ? 1 : 0);
      formData.append("postedOn", postedOn);
      formData.append("timezone", moment.tz.guess());

      if (postedOn2)
         formData.append("postedOn2", postedOn2);

      if (this.state.editwithwho && this.state.editwithwho.length > 0) {
         if (this.state.changewithwho == true) {
            this.state.editwithwho.map((data, i) => {
               formData.append("withwho[]", data);
            });
         }
         else if (this.state.changewithwho == false) {
            this.state.editwithwho.map((datas, i) => {
               formData.append("withwho[]", datas.user_id);
            });
         }
      }
      if (this.state.editwhocansee == 'allfollowersexcept' || this.state.editwhocansee == 'particularfollowers') {
         if (this.state.editwhocanseemeta && this.state.editwhocanseemeta.length > 0) {
            if (this.state.exp == true) {
               this.state.editwhocanseemeta.map((data, i) => {
                  formData.append("whocanseemeta[]", data);
               });
            }
            else if (this.state.exp == false) {
               this.state.editwhocanseemeta.map((datas, i) => {
                  formData.append("whocanseemeta[]", datas.user_id);
               });
            }
            formData.append("whocansee", this.state.editwhocansee);
         }
         else {
            formData.append("whocansee", "everyone");
         }
      }
      else {
         formData.append("whocansee", this.state.editwhocansee);
      }
      if (this.state.postType2 != "") {
         if (this.state.postType2 == "image" || this.state.postType2 == "video") {
            if (this.state.mediaType === "video") this.setState({ isVideoUploading: true });

            formData.append("eventImage", this.state.filewithpath, this.state.filewithpath.name);
            formData.append("mediaType", this.state.postType2);
            formData.append("width", 800);
            formData.append("height", 1000);
         }
         if (this.state.postType2 == "image" || this.state.postType2 == "video") {
         }
      }
      formData.append("removeMedia", this.state.editeventImage == "" ? 1 : 0);
      // for (var [key, value] of formData.entries()) { console.log('11111111111',key, value); }
      var body = formData;
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/event/edit.php', body, {
         headers: {
            Accept: 'multipart/form-data',
            "Content-Type": 'multipart/form-data',
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            this.setState({ isVideoUploading: false });
            if (res.data.message) {
               var e = new Date(newDate);
               localStorage.setItem('moveid', e)
               localStorage.setItem("backid", "front");
               this.props.history.push("/self");
               return;
               return;
            }
         })
         .catch((error) => {
            this.setState({ editerror2: error.response.data.message });
            $(".newscreen01 #one_16 .errorpop").toggle();
            return;
         })
   }
   fetchMoreData() {
      setTimeout(() => {
         this.setState({ activecolor: 'first' });
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         const totaluser = localStorage.getItem('followers');
         const recordp = totaluser / 40;
         if (this.state.perpage >= recordp) {
            this.setState({ hasMore: false });
            return;
         }
         this.setState({ perpage: this.state.perpage + 1 });
         fetch(this.state.baseUrl + '/api/follow/getFollowers.php?user_id=' + variable + '&page=' + this.state.perpage + '&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.followers.push(responseJson.response[i]);
               }
               if (this.state.mutuality != "") {
                  this.setState({ followers: this.state.followers.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() });
               }
               if (this.state.alphabetically != "") {
                  this.setState({
                     followers: this.state.followers.sort((a, b) =>
                        a.screenName.localeCompare(b.screenName))
                  });
               }
               else {
                  this.setState({ followers: this.state.followers });
               }
            });
         this.setState({
            items: this.state.items.concat(Array.from({ length: 40 }))
            , loading: "false"
         });
      }, 1000);
   }

   render() {
      return (
         //<div className="newscreen1 newscreen01">
         //<div className={localStorage.getItem("backid")=="back" ? "screen1 active fadesback" : "screen1 active fades"}  id="one_16">
         <>
            <div className={`${this.props.pos} screen1`} id="one_16">
               <div className="top" id="myHeader">
                  <section className="header headeredit">
                     <div className="row">
                        <div className="col-4 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li onClick={this.gotouser.bind(this)}>
                                 <a className="linkback" data-toggle="tab"><RevisitSvg /></a>
                                 <p class="hoverIconInfo">Revisit</p>
                              </li>
                           </ul>
                        </div>
                        <div className="header_center pl-0 pr-0 text-center">
                           <h6>edit temporal information</h6>
                        </div>
                        {/* <div className="col-4 header_right pl-0">
            </div> */}
                     </div>
                  </section>
               </div>
               <div className="tab-content  pt-0">
                  <div className="sectionone calendar_edit">
                     <div className="inkap">
                        <div className="inkapil">
                           <Calendar
                              onChange={this.onCalendarPostEdit.bind(this)}
                              value={this.state.selectedDate}
                              locale="en-US" // Set locale to US English
                              firstDayOfWeek={0}
                              tileClassName={({ date, view }) => {
                                 const dateStr = moment(date).format("YYYY-MM-DD")

                                 if (dateStr == this.state.firstDate)
                                    return 'react-calendar__tile--active'
                                 else if (dateStr == this.state.secondDate)
                                    return 'pinkSecond'

                              }}
                           />
                        </div>
                     </div>
                     <div className="inkap">
                        <div className="inkapil hours-minutes__block">

                           <table className="table table-bordered text-center mb-0 hddak">
                              <thead>
                                 <tr>
                                    <th>Hour</th>
                                    <th>Minute</th>
                                 </tr>
                              </thead>
                           </table>
                           <table className="table table-bordered text-center mb-1">
                              <tbody>
                                 <tr>
                                    <td onClick={this.getTimeEdit.bind(this)} id="12pm" className="12">12pm</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="6pm" className="6">6pm</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="12am" className="0">12am</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="6am" className="6">6am</td>
                                    <td onClick={this.getminEdit.bind(this)} id="0" className="0">0</td>
                                    <td onClick={this.getminEdit.bind(this)} id="5" className="5">5</td>
                                 </tr>
                                 <tr>
                                    <td onClick={this.getTimeEdit.bind(this)} id="1pm" className="1">1pm</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="7pm" className="7">7pm</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="1am" className="1">1am</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="7am" className="7">7am</td>
                                    <td onClick={this.getminEdit.bind(this)} id="10" className="10">10</td>
                                    <td onClick={this.getminEdit.bind(this)} id="15" className="15">15</td>
                                 </tr>
                                 <tr>
                                    <td onClick={this.getTimeEdit.bind(this)} id="2pm" className="2">2pm</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="8pm" className="8">8pm</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="2am" className="2">2am</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="8am" className="8">8am</td>
                                    <td onClick={this.getminEdit.bind(this)} id="20" className="20">20</td>
                                    <td onClick={this.getminEdit.bind(this)} id="25" className="25">25</td>
                                 </tr>
                                 <tr>
                                    <td onClick={this.getTimeEdit.bind(this)} id="3pm" className="3">3pm</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="9pm" className="9">9pm</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="3am" className="3">3am</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="9am" className="9">9am</td>
                                    <td onClick={this.getminEdit.bind(this)} id="30" className="30">30</td>
                                    <td onClick={this.getminEdit.bind(this)} id="35" className="35">35</td>
                                 </tr>
                                 <tr>
                                    <td onClick={this.getTimeEdit} id="4pm" className="4">4pm</td>
                                    <td onClick={this.getTimeEdit} id="10pm" className="10">10pm</td>
                                    <td onClick={this.getTimeEdit} id="4am" className="4">4am</td>
                                    <td onClick={this.getTimeEdit} id="10am" className="10">10am</td>
                                    <td onClick={this.getminEdit} id="40" className="40">40</td>
                                    <td onClick={this.getminEdit} id="45" className="45">45</td>
                                 </tr>
                                 <tr>
                                    <td onClick={this.getTimeEdit.bind(this)} id="5pm" className="5">5pm</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="11pm" className="11">11pm</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="5am" className="5">5am</td>
                                    <td onClick={this.getTimeEdit.bind(this)} id="11am" className="11">11am</td>
                                    <td onClick={this.getminEdit.bind(this)} id="50" className="50">50</td>
                                    <td onClick={this.getminEdit.bind(this)} id="55" className="55">55</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                  <div className="optional optional2">
                     <div className='d-flex'>
                        <h3>Optional</h3>
                     </div>
                     <div className="row">
                        <div className="col-4 mb-2 pr-2">
                           <div className='flex-1'><p className='col-4'>Visibility</p><span className="col-6 more3 box-border">{this.checkcondition(this.state.editwhocansee)}<img src="../../images/down-facing_arrow.png" /></span></div>
                        </div>
                        <div className="col-4 mb-2 pr-2">
                           <label className="fille mb-0">
                              <span className='box-border'>Browse
                                 <input type='file' onChange={this.editPostImage.bind(this)} />
                                 <img src="../../images/newspaper.png" />
                              </span>
                           </label>
                        </div>
                        <div className="col-3 mb-2 text-right">
                           <label className="mb-0">
                              {/* <label className="checkcontainer">{this.state.editinclude_in_feed==0 ? <><input type="checkbox" onChange={this.checkedit.bind(this)} name="checkbox" defaultChecked/></> : <input type="checkbox" onChange={this.checkedit.bind(this)} name="checkbox" />}<span className="radiobtn"></span>only show on profile</label> */}
                              <label className="checkcontainer"><input type="checkbox" id="editImportant" onChange={this.setEditIsImportant.bind(this)} name="checkbox" checked={this.state.editIsImportant == 1 ? true : false} /><span className="radiobtn text-center"></span>important</label>
                           </label>
                        </div>
                     </div>
                     <h6>
                        <a className="btn post mb-2 box-border" onClick={this.editPost.bind(this)}>Update</a>
                     </h6>
                     {/* <div className="row browser browser2"> */}
                     {/* <div className="col-4">
               <a className="with_who link" onClick={this.eleven.bind(this)} > with who?</a>
            </div> */}
                     {/* <div className="col-4 ">
               <a className="with_who link" onClick={this.editPost.bind(this)}> update</a>
            </div> */}
                     {/* </div> */}
                     <div className="col-12 mb-2 pl-2">

                        {
                           this.state.imageVideoUrl2 != "" ? this.state.postType2 == "image" ?
                              this.state.imageVideoUrl2 ? <div className="imagesDiv"><div className="btncrose" onClick={this.closeImage.bind(this)}><img class="closeImgae" src="../../images/closeImage.png" /></div><img id="blah2" class="w-100" src={this.state.imageVideoUrl2} alt="your image" /></div> : "" :
                              this.state.imageVideoUrl2 ?
                                 <div className="imagesDiv"><div class="video-div">

                                    <Player
                                       ref={player => {
                                          this.player = player;
                                       }}
                                       autoPlay={true}
                                    >
                                       <div className="btncrose" onClick={this.closeImage.bind(this)} ><img class="closeImgae" src="../../images/redcross.png" /></div>
                                       <source src={this.state.imageVideoUrl2} type="video/mp4" />
                                       <ControlBar autoHide={false} className="my-class" />
                                    </Player>


                                    <ul class="videoulkap pb-3">
                                       <li><Button onClick={this.changeCurrentTime(-10).bind(this)} ><img class="img1" src="../../images/Backwards.png" />
                                          <span>10</span></Button>
                                       </li>
                                       <li><img class="img2" src="../../images/pause.png" /></li>
                                       <li><Button onClick={this.changeCurrentTime(10).bind(this)} ><img class="img3" src="../../images/Forwards.png" />
                                          <span>10</span></Button>
                                       </li>
                                    </ul>
                                 </div> </div> : ""

                              : this.state.editmediaType == "image" ? this.state.editeventImage ? <div className="imagesDiv"><div className="btncrose" onClick={this.closeImage.bind(this)}><img class="closeImgae" src="../../images/closeImage.png" /></div><img id="blah2" class="w-100" src={this.state.baseUrl + this.state.editeventImage} alt="your image" /></div>
                                 : "" : this.state.editeventImage ? <div className="imagesDiv"><div class="video-div">

                                    <Player
                                       ref={player => {
                                          this.player = player;
                                       }}
                                       autoPlay={true}
                                    >
                                       <div className="btncrose" onClick={this.closeImage.bind(this)} ><img class="closeImgae" src="../../images/redcross.png" /></div>
                                       <source src={this.state.baseUrl + this.state.editeventImage} type="video/mp4" />
                                       <ControlBar autoHide={false} className="my-class" />
                                    </Player>
                                    <ul class="videoulkap pb-3">
                                       <li><Button onClick={this.changeCurrentTime(-10).bind(this)} ><img class="img1" src="../../images/Backwards.png" />
                                          <span>10</span></Button>
                                       </li>
                                       <li><img class="img2" src="../../images/pause.png" /></li>
                                       <li><Button onClick={this.changeCurrentTime(10).bind(this)} ><img class="img3" src="../../images/Forwards.png" />
                                          <span>10</span></Button>
                                       </li>
                                    </ul>
                                 </div> </div>
                                 : ""}
                     </div>
                  </div>
               </div>
               <Footer {...this.props} />
               <div className="op pupup6">
                  <div onClick={this.onCancelEdit.bind(this)} className="more3 h-100"></div>
                  <ul>
                     <li><a className="more3" onClick={this.onEveryoneEdit.bind(this)} ><img src="../../images/everyone.png" /> everyone</a></li>
                     {/* <li><a  className="more3" onClick={this.onFollowersEdit.bind(this)} ><img src="../../images/arocicon.png"/> followers</a></li>
         <li><a className="more3" onClick={this.onFollowingEdit.bind(this)} ><img src="../../images/usercicon.png"/> users I'm following</a></li>
         <li><a className="more3 link" onClick={this.nineghty.bind(this)} ><img src="../../images/seeLess.png"/> all followers except...</a></li>
         <li><a className="more3 link" onClick={this.twenty.bind(this)}><img src="../../images/tkicon.png"/> only these followers...</a></li> */}
                     <li><a className="more3" onClick={this.onOnlymeEdit.bind(this)}><img src="../../images/onlyme.png" /> only me</a></li>
                     <button className="more3 upbtn" onClick={this.onCancelEdit.bind(this)}>Cancel</button>
                  </ul>
               </div>
               {this.state.isVideoUploading && (
                  <CustomPopUp
                     isOpen={this.state.isVideoUploading}
                     onClose={() => this.setState({ isVideoUploading: false })}
                     popup={{
                        heading: "Video uploading",
                        message: "Your video is uploading; it will take some time to appear on your timetable.",
                        onClick: () => {
                           this.setState({ isVideoUploading: false });
                        },
                     }}
                     multipleButton={false}
                  />
               )}
            </div>
            <div className="screen1" id="one_11">
               <div className="top" id="myHeader">
                  <section className="header headernoti2">
                     <div className="row">
                        <div className="col-4 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li><a className="linkback" data-toggle="tab" onClick={this.gobackone.bind(this)} ><img src="../../images/back.png" align="img" /></a></li>
                           </ul>
                        </div>
                        <div className="col-4 header_center pl-0 pr-0 text-center">
                           <h6>with who?</h6>
                        </div>
                        <div className="col-4 header_right pl-0">
                           <ul className="uld">
                              <li><a className="link " ><img onClick={this.withwho.bind(this)} src="../../images/tick.png" align="img" /></a></li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <div className="header_search">
                     <form className="form-inline mt-md-0">
                        <input className="form-control" id="lemon" type="text" onKeyUp={this.onKeyUpValueFollower.bind(this)} placeholder="search" aria-
                           label="Search" />
                        {(() => {
                           if (this.state.croseIcone.length > 0) {
                              return (<span className="btn"><img className="" onClick={this.removefollowertext.bind(this)} src="../../images/close(1).png" /></span>);
                           }
                           else {
                              return (<span className="btn"><img className="" src="../../images/search.png" /></span>);
                           }
                        })()}
                     </form>
                  </div>
               </div>
               <div className="followingmain withwho">
                  <div className="row py-0 my-0">
                     <div
                        id="scrollableDivto"
                        className="scrollClass newscreen1"
                        style={{
                           overflow: 'auto',
                           display: 'row',
                           flexDirection: 'row',
                           margintop: 47,
                        }}
                     >
                        {(() => {
                           if (this.state.followerSearch.length > 0) {
                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.itemsearchfo.length}
                                    next={this.fetchMoreDatafollowerSearch.bind(this)}
                                    className="row"
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    hasMore={true}
                                    loader={
                                       <div className={this.weekforNext()}>
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivto"
                                 >

                                    {this.state.followerSearch.map((c, i) => {

                                       return (

                                          <div className="col-12">
                                             <div id={'plug' + c.user_id} className={this.state.editwithwho ? this.state.editwithwho.findIndex(x => x.user_id === c.user_id) !== -1 ? "userin act" : "userin" : "userin"}>
                                                <label className="checkcontainer">
                                                   <input type="checkbox" value={c.user_id} id={c.user_id} className="checkst"
                                                      onChange={this.handleChangewithwhoplug.bind(this)} name="checkbox" /><span className="radiobtn"></span>
                                                   <span className="imgg">
                                                      <img className="u_img" src={this.state.baseUrl + c.profileImage} align="images" />
                                                   </span>
                                                   <h6>{c.screenName}</h6>
                                                   <p>{c.mutual} mutual</p>
                                                </label>
                                             </div>
                                          </div>


                                       )

                                    }
                                    )
                                    }
                                 </InfiniteScroll>
                              )
                           }
                           else {

                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={this.fetchMoreData.bind(this)}
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    className="row"
                                    hasMore={true}
                                    loader={
                                       <div className={this.weekforNext()}>
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivto"
                                 >
                                    {
                                       this.state.followers.map((p, i) => {
                                          return (
                                             <div className="col-12">
                                                <div id={'slug' + p.user_id} className={this.state.editwithwho ? this.state.editwithwho.findIndex(x => x.user_id === p.user_id) !== -1 ? "userin active" : "userin" : "userin"}>
                                                   <label className="checkcontainer">
                                                      <input type="checkbox" value={p.user_id}
                                                         onChange={this.handleChangewithwho.bind(this)} name="checkbox" className="checkst" /><span className="radiobtn"></span>
                                                      <span className="imgg">
                                                         <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" />
                                                      </span>
                                                      <h6>{p.screenName}</h6>
                                                      <p>{p.mutual} mutual</p>
                                                   </label>
                                                </div>
                                             </div>
                                          )
                                       }
                                       )
                                    }
                                 </InfiniteScroll>
                              );

                           }
                        })()}



                     </div>



                  </div>
               </div>
               <Footer {...this.props} />
            </div>
            {/* <!--************************ screen1 19 *************************--> */}
            <div className="screen1" id="one_19">
               <div className="top" id="myHeader">
                  <section className="header headernoti2">
                     <div className="row">
                        <div className="col-1 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li><a className="linkback" data-toggle="tab" onClick={this.gobacktwo.bind(this)}><img src="../../images/back.png" align="img" /></a></li>
                           </ul>
                        </div>
                        <div className="col-10 header_center pl-0 pr-0 text-center">
                           <h6>all followers except...</h6>
                        </div>
                        <div className="col-1 header_right pl-0">
                           <ul className="uld">
                              <li><a className="link more3" onClick={this.except.bind(this)} data-toggle="tab" ><img src="../../images/tick.png" align="img" /></a></li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <div className="header_search">
                     <form className="form-inline mt-md-0">
                        <input className="form-control" id="lemon" type="text" onKeyUp={this.onKeyUpValueFollower.bind(this)} placeholder="search" aria-
                           label="Search" />
                        {(() => {
                           if (this.state.croseIcone.length > 0) {
                              return (<span className="btn"><img className="" onClick={this.removefollowertext.bind(this)} src="../../images/close(1).png" /></span>);
                           }
                           else {
                              return (<span className="btn"><img className="" src="../../images/search.png" /></span>);
                           }
                        })()}
                     </form>
                  </div>
               </div>
               <div className="followingmain withwho">
                  <div className="row py-0 my-0">
                     <div
                        id="scrollableDivfourth"
                        className="scrollClass newscreen1"
                        style={{
                           overflow: 'auto',
                           display: 'row',
                           flexDirection: 'row',
                           margintop: 47,
                        }}
                     >
                        {(() => {
                           if (this.state.followerSearch.length > 0) {
                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.itemsearchfo.length}
                                    next={this.fetchMoreDatafollowerSearch.bind(this)}
                                    className="row"
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    hasMore={true}
                                    loader={
                                       <div className="loaderclass">
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivfourth"
                                 >

                                    {this.state.followerSearch.map((c, i) => {
                                       return (
                                          <div className="col-12">
                                             <div className={this.state.editwhocanseemeta && this.state.editwhocansee == "allfollowersexcept" ? this.state.editwhocanseemeta.findIndex(x => x.user_id === c.user_id) !== -1 ? "userin act" : "userin" : "userin"} id={"elugsearch" + c.user_id}>
                                                <label className="checkcontainer">
                                                   <input type="checkbox" value={c.user_id} onChange={this.handleChangeexceptsearch.bind(this)} name="checkbox" /><span className="radiobtn"></span>
                                                   <span className="imgg">
                                                      <img className="u_img" src={this.state.baseUrl + c.profileImage} align="images" />
                                                   </span>
                                                   <h6>{c.screenName}</h6>
                                                   <p>{c.mutual} mutual</p>
                                                </label>
                                             </div>
                                          </div>


                                       )
                                    }
                                    )
                                    }
                                 </InfiniteScroll>
                              )
                           }
                           else {
                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={this.fetchMoreData.bind(this)}
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    className="row"
                                    hasMore={true}
                                    loader={
                                       <div className={this.weekforNext()}>
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivfourth"
                                 >
                                    {
                                       this.state.followers.map((p, i) => {
                                          return (

                                             <div className="col-12">
                                                <div className={this.state.editwhocanseemeta && this.state.editwhocansee == "allfollowersexcept" ? this.state.editwhocanseemeta.findIndex(x => x.user_id === p.user_id) !== -1 ? "userin active" : "userin" : "userin"} id={"elug" + p.user_id}>
                                                   <label className="checkcontainer">
                                                      <input type="checkbox" value={p.user_id} onChange={this.handleChangeexcept.bind(this)} name="checkbox" /><span className="radiobtn"></span>
                                                      <span className="imgg">
                                                         <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" />
                                                      </span>
                                                      <h6>{p.screenName}</h6>
                                                      <p>{p.mutual} mutual</p>
                                                   </label>
                                                </div>
                                             </div>

                                          )
                                       }
                                       )
                                    }
                                 </InfiniteScroll>
                              );

                           }
                        })()}
                     </div>
                  </div>
               </div>
               <Footer {...this.props} />
            </div>




            {/* 
<!--************************ screen1 20 *************************--> */}
            <div className="screen1" id="one_20">
               <div className="top" id="myHeader">
                  <section className="header headernoti2">
                     <div className="row">
                        <div className="col-1 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li><a className="linkback" onClick={this.gobackthree.bind(this)} ><img src="../../images/back.png" align="img" /></a></li>
                           </ul>
                        </div>
                        <div className="col-10 header_center pl-0 pr-0 text-center">
                           <h6>only these followers...</h6>
                        </div>
                        <div className="col-1 header_right pl-0">
                           <ul className="uld">
                              <li><a className="link more3" onClick={this.unexcept.bind(this)} ><img src="../../images/tick.png" align="img" /></a></li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <div className="header_search">
                     <form className="form-inline mt-md-0">
                        <input className="form-control" id="lemon" type="text" onKeyUp={this.onKeyUpValueFollower.bind(this)} placeholder="search" aria-
                           label="Search" />
                        {(() => {
                           if (this.state.croseIcone.length > 0) {
                              return (<span className="btn"><img className="" onClick={this.removefollowertext.bind(this)} src="../../images/close(1).png" /></span>);
                           }
                           else {
                              return (<span className="btn"><img className="" src="../../images/search.png" /></span>);
                           }
                        })()}
                     </form>
                  </div>
               </div>
               <div className="followingmain withwho">
                  <div className="row py-0 my-0">
                     <div
                        id="scrollableDivfifth"
                        className="scrollClass newscreen1"
                        style={{
                           overflow: 'auto',
                           display: 'row',
                           flexDirection: 'row',
                           margintop: 47,
                        }}
                     >
                        {(() => {
                           if (this.state.followerSearch.length > 0) {
                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.itemsearchfo.length}
                                    next={this.fetchMoreDatafollowerSearch.bind(this)}
                                    className="row"
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    hasMore={true}
                                    loader={
                                       <div className="loaderclass">
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivfifth"
                                 >
                                    {
                                       this.state.followers.map((p, i) => {
                                          return (
                                             <div className="col-12">
                                                <div className={this.state.editwhocanseemeta && this.state.editwhocansee == "particularfollowers" ? this.state.editwhocanseemeta.findIndex(x => x.user_id === p.user_id) !== -1 ? "userin act" : "userin" : "userin"} id={"thesesearch" + p.user_id}>
                                                   <label className="checkcontainer">
                                                      <input type="checkbox" value={p.user_id} onChange={this.handleChangeunexceptsearch.bind(this)} name="checkbox" /><span className="radiobtn"></span>
                                                      <span className="imgg">
                                                         <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" />
                                                      </span>
                                                      <h6>{p.screenName}</h6>
                                                      <p>{p.mutual} mutual</p>
                                                   </label>
                                                </div>
                                             </div>

                                          )
                                       }
                                       )

                                    }
                                 </InfiniteScroll>
                              )
                           }
                           else {

                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={this.fetchMoreData.bind(this)}
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    className="row"
                                    hasMore={true}
                                    loader={
                                       <div className={this.weekforNext()}>
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivfifth"
                                 >
                                    {
                                       this.state.followers.map((p, i) => {
                                          return (
                                             <div className="col-12">
                                                <div className={this.state.editwhocanseemeta && this.state.editwhocansee == "particularfollowers" ? this.state.editwhocanseemeta.findIndex(x => x.user_id === p.user_id) !== -1 ? "userin active" : "userin" : "userin"} id={"these" + p.user_id}>
                                                   <label className="checkcontainer">
                                                      <input type="checkbox" value={p.user_id} onChange={this.handleChangeunexcept.bind(this)} name="checkbox" /><span className="radiobtn"></span>
                                                      <span className="imgg">
                                                         <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" />
                                                      </span>
                                                      <h6>{p.screenName}</h6>
                                                      <p>{p.mutual} mutual</p>
                                                   </label>
                                                </div>
                                             </div>

                                          )
                                       }
                                       )

                                    }
                                 </InfiniteScroll>
                              );
                           }
                        })()}
                     </div>
                  </div>
               </div>
               <Footer {...this.props} />
            </div>
         </>
         //</div>
      )
   }
};

export default EditPost;