import axios from 'axios';
import { removeDuplicateObjectsFromArray } from '../utils/arrayHelper';
import moment from 'moment';

const server1 = process.env.REACT_APP_BASE_URL_NODE + '/api/';
const server2 = process.env.REACT_APP_BASE_URL + '/api/';

export const usersListForGuets = async (page = 1, limit = 256) => {
    if (page === 1 && localStorage.getItem("entry") && JSON.parse(localStorage.getItem("entry")) > 0 | JSON.parse(localStorage.getItem("entry")) != null) {
        return JSON.parse(localStorage.getItem("entry"));
    }
    else {
        try {
            let data = await axios.get(`${server1}most-visited-profiles-guest?page=${page ? page : 1}&limit=${limit}`);
            if (data.data.data) {
                if (page === 1 || page == undefined) {
                    // localStorage.setItem("entry", JSON.stringify(data.data.data));
                    localStorage.setItem("mostVisitedProfiles", JSON.stringify(data.data.data));
                }
                return data.data.data;
            } else {
                console.error("fetch usersListForGuets failed: ", data.data.message);
                return [];
            }
        }
        catch (e) {
            console.error("fetch usersListForGuets failed: ", e.message);
            return [];
        }
    }
}

export const userListForLogin = async (page = 1, limit = 256) => {
    if (page === 1 && localStorage.getItem("entry") && JSON.parse(localStorage.getItem("entry")) > 0 | JSON.parse(localStorage.getItem("entry")) != null) {
        return JSON.parse(localStorage.getItem("entry"));
    }
    else {

        const token = localStorage.getItem('timeToken');
        try {
            let data = await axios.get(`${server1}most-visited-profiles?page=${page ? page : 1}&limit=${limit}`, {

                headers: {
                    "x-access-token": token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            });
            if (data) {
                if (page === 1 || page == undefined) {
                    // localStorage.setItem("entry", JSON.stringify(data.data.data));

                    localStorage.setItem("mostVisitedProfiles", JSON.stringify(data.data.data));
                }
                return data.data.data;
            } else {
                console.error("fetch userListForLogin failed: ", data.data.message);
                return [];
            }
        }
        catch (e) {
            console.error("fetch userListForLogin failed: ", e.message);
            return [];
        }
    }
}

export const recentListForLogin = async () => {
    const token = localStorage.getItem('timeToken');
    try {
        let res = await axios.get(`${server1}recently-visited-profiles`, {

            headers: {
                "x-access-token": token,
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        });
        if (res.status == 200) {
            localStorage.setItem("recents", JSON.stringify(res.data.data));
            return res.data.data;
        } else {
            console.error("fetch recentListForLogin failed: ", res.data.message);
            return [];
        }
    }
    catch (e) {
        console.error("fetch recentListForLogin failed: ", e.message);
        return [];
    }
}


export const getUserRecommedations = async (userId) => {
    if (!userId)
        return []

    try {
        const token = localStorage.getItem('timeToken');
        let path = token ? "user/recommendations" : "user/recommendations-guest"

        let url = `${server1}${path}?user_id=${userId}`
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        }

        if (token)
            headers["x-access-token"] = token

        let res = await axios.get(url, { headers });
        if (res.status == 200)
            return res.data.data;
        else {
            console.error("fetch getUserRecommedations failed: ", res.data.message);
            return [];
        }
    }
    catch (e) {
        console.error("fetch getUserRecommedations failed: ", e.message);
        return [];
    }
}

export const addToHighInterestedProfiles = async (userId, page = 1) => {
    if (!userId)
        return []

    try {
        const token = localStorage.getItem('timeToken');

        let url = `${server1}user/see-more?user_id=${userId}&page=${page}`

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        }

        if (token)
            headers["x-access-token"] = token

        let res = await axios.get(url, { headers });

        if (res.status == 200)
            return res.data.data;
        else {
            console.error("fetch getUserRecommedations failed: ", res.data.message);
            return [];
        }
    }
    catch (e) {
        console.error("fetch getUserRecommedations failed: ", e.message);
        return [];
    }
}

export const addToLessInterestedProfiles = async (userId, page = 1) => {
    if (!userId)
        return false

    try {
        const token = localStorage.getItem('timeToken');

        let url = `${server1}user/see-less?user_id=${userId}`

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        }

        if (token)
            headers["x-access-token"] = token

        let res = await axios.get(url, { headers });

        if (res.status == 200)
            return true;
        else {
            console.error("fetch getUserRecommedations failed: ", res.data.message);
            return false;
        }
    }
    catch (e) {
        console.error("fetch getUserRecommedations failed: ", e.message);
        return false;
    }
}

export const getRecommendationsFromPastVisitsForLogin = async () => {

    try {
        const token = localStorage.getItem('timeToken');

        let url = `${server1}user/recommendations/past-visit`

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
        }

        let res = await axios.get(url, { headers });

        if (res.status == 200)
            return res.data.data;
        else {
            console.error("fetch recommendationsFromPastVisitsForLogin failed: ", res.data.message);
            return [];
        }
    }
    catch (e) {
        console.error("fetch recommendationsFromPastVisitsForLogin failed: ", e.message);
        return [];
    }
}

/**
 * 
 * @param {string} search search text
 * @param {number} page page number
 * @param {number} skip profiles to skip
 * @returns 
 */
export const findUser = async (search, page = 1, skip = 0, limit = 60) => {
    if (!search)
        return []

    try {
        const token = localStorage.getItem('timeToken');

        let url = `${server1}search?search=${search}&page=${page}&skip=${skip}&limit=${limit}`

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        }

        if (token)
            headers["x-access-token"] = token

        let res = await axios.get(url, { headers });

        if (res.status == 200)
            return res.data.data;
        else {
            console.error("searchUser failed: ", res.data.message);
            return [];
        }
    }
    catch (e) {
        console.error("searchUser failed: ", e.message);
        return [];
    }
}

export const fetchSortedUser = async (type, users) => {
    try {
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
        let payload = { "user_ids": users };

        let res = await axios.post(`${server1}sort-profiles/${type}`, payload, { headers });

        if (res.status == 200)
            return res.data.data;
        else {
            console.error("fetch sorted users failed: ", res.data.message);
            return [];
        }
    }
    catch (e) {
        console.error("fetch sorted users failed: ", e.message);
        return [];
    }
}

export const fetchEventsListGuest = async (profileId) => {
    const header = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "TimeZone": moment.tz.guess(),
        }
    }
    try {
        let res = await axios.get(`${server2}event/profileEventDatesGuest.php?profileId=${profileId}`, header);

        if (res.status == 200)
            return res.data.response;
        else {
            console.error("fetch events list failed: ", res.data.message);
            return [];
        }
    }
    catch (e) {
        console.error("fetch events list failed: ", e.message);
        return [];
    }
}

export const fetchEventsListLogin = async (profileId) => {
    const token = localStorage.getItem('token');
    const timeToken = localStorage.getItem('timeToken');
    
    const header = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "x-access-token": timeToken,
            "Content-Type": "application/json",
            "TimeZone": moment.tz.guess(),
        }
    }
    try {
        let res = await axios.get(`${server2}event/profileEventDates.php?profileId=${profileId}`, header);

        if (res.status == 200)
            return res.data.response;
        else {
            console.error("fetch events list failed: ", res.data.message);
            return [];
        }
    }
    catch (e) {
        console.error("fetch events list failed: ", e.message);
        return [];
    }
}

export const fetchSavedTemporalInfo = async (eventOnDate) => {
    const token = localStorage.getItem('token');
    const timeToken = localStorage.getItem('timeToken');

    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": timeToken,
        TimeZone: moment.tz.guess(),
        Authorization: `Bearer ${token}`,
    };

    const url = `${server1}me/saved-ti${eventOnDate ? `?eventOnDate=${encodeURIComponent(eventOnDate)}` : ''}`;

    try {
        const response = await axios.get(url, { headers });

        return response.status === 200 ? response.data.data : [];
    } catch (error) {
        console.error("fetch saved temporal information failed:", error.message);
        return [];
    }
};

export const saveTemporalInfo = async (eventId) => {
    const token = localStorage.getItem('token');
    const timeToken = localStorage.getItem('timeToken');

    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": timeToken,
        Authorization: `Bearer ${token}`,
    };

    const url = `${server1}events/${eventId}/toggle-save`;

    try {
        const response = await axios.patch(url, {}, { headers });
        return response.data;
    } catch (error) {
        console.error("save temporal information failed:", error?.message);
        return { success: false, message: error?.message };
    }
};

export const switchAccount = async (profileType) => {
    const token = localStorage.getItem('token');
    const timeToken = localStorage.getItem('timeToken');

    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": timeToken,
        Authorization: `Bearer ${token}`,
    };

    const url = `${server1}users/me/switch-account-type`;

    try {
        const response = await axios.patch(url, { "profileType" : profileType }, { headers });
        return response.data;
    } catch (error) {
        console.error("Switch account failed:", error?.message);
        return { success: false, message: error?.message };
    }
}
